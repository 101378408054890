import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/infogramas1.png"
import img1 from "../../../../static/img/irstrat/img1.jpg"
import info2 from "../../../../static/img/irstrat/canales.png"
import img2 from "../../../../static/img/irstrat/estrategia.png"
import info3 from "../../../../static/img/irstrat/ventaja_competitiva.png"
import pilares from "../../../../static/img/blog/4pilares-1140.jpg";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Cuatro pilares para una exitosa comunicación con inversionistas'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={pilares}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                19 Nov. 2019
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Manuel Pérez
                </span>
                                <span>
                    Comunicación
                </span>
                            </div>
                        </div>

                        <h2>I. “Información de interés”</h2>
                        <br/>
                        <h6>¿Sigue siendo la información sobre coeficientes financieros clásicos: EPS “Earnings Per
                            Share”,
                            UAFIDA/Ventas y P/VL la principal fuente de análisis para la toma de decisiones de
                            inversión? La respuesta
                            es “no”; definitivamente, sí es información consultada e importante, pero no decisiva dentro
                            de la tesis
                            de
                            inversión de los financieros en la actualidad.</h6>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>
                        Recientemente, PwC “Price Waterhouse Coopers” condujo su PwC’s Investor Survey (*),
                        enfocado a obtener
                        los elementos más ponderados dentro del proceso de análisis de inversión. Según el estudio de la
                        consultoría
                        londinense, 70% de las instituciones encuestadas (**) consideraron al flujo de efectivo
                        operativo como el
                        principal factor de análisis, y del que esperaban una explicación robusta por parte de las
                        emisoras. Los
                        otros cinco principales factores, en orden de importancia, fueron:
                    </p>
                    <img className="w-100 wow fadeIn" src={info1} alt="" data-wow-delay="0.2s"
                         data-wow-duration="2.0s"/>
                    <p className={"mt-10"}>El resultado de este estudio exhibe la necesidad imperante de coordinación
                        entre las
                        áreas generadoras de
                        información (planeación y contraloría) con el área de comunicación y de Relaciones con
                        Inversionistas (RI). Para
                        el desempeño de una exitosa función, el IRO “Investor Relations Officer” deberá estar, cada vez
                        más,
                        familiarizado con las entrañas de los estados financieros, y en particular con los usos y
                        fuentes
                        integrantes del estado de flujos de efectivo.</p>
                    <p>Las áreas más representativas de una empresa para considerarse en el análisis de inversión, de
                        acuerdo con
                        PwC, en orden descendente de importancia, fueron:</p>
                    <div className="row pt-3 pb-3">
                        <div className="col-md-4">
                            <img className="w-100 wow fadeInLeft" src={img1} alt="" data-wow-delay="0.2s"
                                 data-wow-duration="2.0s"/>
                        </div>
                        <div className="col-md-8">
                            <ul className="">
                                <li>Administración de riesgos</li>
                                <li>Planeación estratégica; y,</li>
                                <li>Compensación y planes de sucesión ejecutiva
                                    (desarrollo
                                    interno de
                                    talento)
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p className={"mt-10"}>Claramente el “sexiness” de las áreas de ventas y del “front office” en
                        general ha
                        disminuido ante los ojos
                        de los inversionistas; demostrando que las ventas no lo son todo en la tesis de inversión. A
                        partir del
                        2008, surge un inversionista cauteloso; enfocado en la Administración, los procesos de
                        “backoffice”, la
                        calidad de los reportes financieros y en la administración de riesgos. Ante esta coyuntura, es
                        clara la
                        importancia de la función de RI para proporcionar información pertinente, y preparar reportes de
                        resultados
                        de máxima calidad con explicaciones a la altura de las expectativas del nuevo prototipo de
                        inversionista.</p>


                    <small>(*) Ver: <a href="https://www.pwc.com" target="_blank">www.pwc.com</a>.</small>
                    <br/>
                    <small>(**) En la encuesta: fondos gubernamentales de inversión representaron el 40%;
                        administradores de
                        portafolios, 17%; Aseguradoras, 6%; y, el resto fue integrado por diversos perfiles de
                        inversionistas.
                    </small>

                </div>
                <div className="main_blog_item">
                    <h2>II. “Guidance”</h2>
                    <br/>
                    <h6>El término “guidance” es un “must” en la comunicación con nuestros inversionistas que nos evoca
                        de
                        inmediato varios conceptos; siendo el primero de ellos los “pronósticos” de la Administración
                        sobre ventas
                        y utilidades. En México principalmente se ofrece el “guidance” sobre ventas y utilidades de
                        manera
                        cuantitativa y en base anual, dándose a conocer generalmente en la época de reporte de febrero.
                        Rondan
                        muchas dudas alrededor de la práctica del “guidance”, ¿es conveniente ofrecerlo? ¿Es
                        estrictamente
                        cuantitativo? ¿Tiene que enfocarse sólo en ventas y utilidades?</h6>
                </div>
                <div className="s_main_text">
                    <p>
                        Definitivamente, no hay nada escrito en piedra respecto a la práctica del “guidance”; no
                        podríamos responder
                        a los cuestionamientos del párrafo anterior con un tajante “sí” o “no”. En el caso de México y
                        en la mayor
                        parte de Latam se ha adoptado una forma de “guidance” a la americana, es decir para la mayoría
                        de nuestras
                        empresas lo importante es dar un número pronóstico sobre ventas y utilidades, y hemos
                        “climatizado” el
                        periodo de pronóstico a una base anual, no obstante que en EE.UU. se ofrece mayormente en base
                        trimestral.
                    </p>

                    <p>A partir del 2008, ante el contexto de inestabilidad, muchas empresas norteamericanas
                        tomaron diferentes medidas sobre la práctica de “guidance”. Algunas lo mantuvieron, otras
                        decidieron hacer
                        un “blend” de los típicos pronósticos sobre ventas y utilidades con ciertos KPIs “Key
                        Performance
                        Indicators”, y muchas otras simplemente lo erradicaron. Los sondeos nos dejan ver de manera
                        clara los
                        efectos del 2008 sobre la práctica de usar “guidance” en las empresas norteamericanas, de
                        acuerdo con el “IR
                        Magazine Global Practice Report 2012”* se halló que 36% de las empresas norteamericanas no
                        ofrecen ningún
                        “guidance”, mientras que asiáticos y europeos no ofrecen en sólo 29% y 31% de los casos,
                        respectivamente.</p>
                    <p>Es importante mostrar los casos de aquéllos que dejaron atrás el “guidance”. Uno de los casos más
                        representativos fue el de Unilever que en 2009 anunció que dejaría de proveer “guidance”, por lo
                        que vio
                        caer su acción en 6% después del anuncio, tomando también los daños en visibilidad debido a las
                        respectivas
                        pérdidas de coberturas de analistas.</p>

                    <p>El estilo europeo de comunicación de “guidance” no está tan difundido en México, pero sin duda es
                        importante conocerlo para ampliar nuestra perspectiva. En el caso de las empresas europeas, el
                        estilo de
                        “guidance” gira más hacia lo cualitativo. De acuerdo con la UK IR Society**, sólo 10% de las
                        empresas del
                        FTSE 100 ofrecen un “guidance” cuantitativo, mientras 65% ofrecen un “guidance” cualitativo.</p>
                    <p>La coyuntura nos indica claramente que el “guidance” se puede flexibilizar, pudiéndose combinar
                        los
                        pronósticos sobre “soft” KPIs (por ejemplo reducción de costos) con los clásicos pronósticos de
                        ventas y
                        utilidades. El “timing” también se puede combinar, por ejemplo se pueden ofrecer pronósticos
                        bianuales en
                        ventas y utilidades con pronósticos trimestrales de KPIs. Al final de la jornada los
                        inversionistas son como
                        viajeros de crucero, y quieren saber a qué puertos van a llegar, por lo que es crucial
                        mantenerlos
                        informados de la manera más conveniente, manteniendo en todo momento la credibilidad del capitán
                        del
                        barco.</p>
                    <p>Respecto a tener “guidance” o no tenerlo, la respuesta es más clara, siempre es preferible tener
                        un
                        “guidance”, ya sea cuantitativo o cualitativo. Claro, habrá casos particulares como el de las
                        empresas de
                        “commodities” donde la predicción de precios se complica, en esos casos lo mejor es ser siempre
                        claro con el
                        inversionista respecto a la incertidumbre circundante, antes que dar pronósticos aventurados. El
                        trabajo y
                        la experiencia del IRO para conocer la naturaleza del negocio y crear un “mix”
                        cualitativo-cuantitativo
                        óptimo para proporcionar el “guidance” serán fundamentales en la percepción de las emisoras.</p>
                    <small>(*) Ver: <a target={"_blank"} href={"https://www.irmagazine.com/"}>www.irmagazine.com</a>
                    </small>
                    <br/>
                    <small>(**) Ver: <a href={"https://irsociety.org.uk/"} target={"_blank"}>www.irs.org.uk</a></small>


                </div>
                <div className="main_blog_item">
                    <h2>III. Canales de divulgación</h2>
                    <br/>
                    <h6>Sin duda en materia de Relaciones con Inversionistas (RI), el contenido es rey, pero de poco
                        sirve que
                        una emisora tenga el mejor contenido si éste no llega a su base y/o lista de prospección de
                        inversionistas. En México los canales más empleados para la difusión de resultados son el sitio
                        de RI, los
                        servicios de wires y las teleconferencias de resultados, mismos que van acompañados de los
                        respectivos
                        filings ante la BMV y la SEC (según sea el caso). En este punto es importante cuestionarnos
                        sobre la
                        suficiencia, vigencia y superioridad tecnológica del uso de estos canales.</h6>
                </div>
                <div className="s_main_text">
                    <p>
                        Definitivamente, el uso adecuado y combinado del sitio de RI, de wires y teleconferencias es
                        suficiente y
                        vigente para la divulgación de resultados, mas necesita ser complementado por los nuevos canales
                        de
                        difusión: videos, webcasts y redes sociales para estar a la vanguardia en tecnología y
                        mejores prácticas.
                        El uso de los wires en prácticas de RI se remonta a 1965, fecha en que PR Newswire crea el
                        Investors
                        Research Wire, mientras las teleconferencias se remontan a inicios de los 80s, con los servicios
                        ofrecidos
                        por First Call. En lo referente a sitios de RI, estos surgen en el 2000 con la implementación de
                        la Reg. FD,
                        por parte de la SEC.
                    </p>

                    <p>La vigencia de los sitios de RI se mantiene, aunque para ello su tecnología se ha escalado a
                        plataformas
                        móviles e interactivas (descargas en múltiples formatos, adecuación a smartphones y tabletas,
                        uso de
                        alertas, RSS, etc.). Los wires también se han mantenido vigentes, a través de su adecuación al
                        uso masivo
                        del internet, mientras que en el caso de las teleconferencias no podemos decir lo mismo, ya que
                        han sido
                        sustituidas por los webcasts como mejor práctica; el uso del video streaming también se ha
                        extendido a
                        reportes anuales y comunicaciones del mando ejecutivo “C-Suite”, tal vez el ejemplo más
                        destacado del uso de
                        videos para comunicaciones corporativas sean los Vlogs empleados por Dell Computers.</p>
                    <p>Las redes sociales merecen una mención aparte al del uso de videos y webcasts, por los mitos y
                        creencias
                        que se han desarrollado a su alrededor, a partir de su inclusión en la Reg FD. El uso de redes
                        sociales
                        constituye un canal alterno a los medios más empleados (Filings, teleconferencias, sitios de RI
                        y wires), y
                        sólo bajo ciertas condiciones puede constituirse en el principal canal de divulgación; su uso
                        debe ser bien
                        comprendido para evitar caer en problemas regulatorios, como el de Netflix, en 2012, donde su
                        CEO divulgó un
                        mensaje desde su cuenta de Facebook (FB), causando el extrañamiento en las autoridades.</p>

                    <p>De acuerdo con el BMO*, 33% de los inversionistas retail acceden a redes sociales en búsqueda de
                        información para invertir, mientras que el 57% accede a los medios tradicionales, TV, prensa,
                        radio, etc. El
                        33% que accede a redes sociales, no concentra su uso en alguna red en particular, siendo FB y
                        twitter los
                        más representativos, con un 9% y 4%, respectivamente. En el caso de los inversionistas
                        institucionales,
                        muchos de ellos no tienen acceso en sus PCs de oficina a las redes, siendo Twitter su fuente más
                        accesible,
                        a través del “Twitter Feed” de Bloomberg.</p>
                    <p>Empresas como Cemex han empleado campañas focalizadas como complemento a sus canales
                        convencionales de
                        divulgación. Cemex focalizó sus campañas de difusión en medios de EE.UU. y Gran Bretaña, teniendo
                        entre ellos
                        al Wall Street Journal, the New York Times y the Financial Times.</p>
                    <img className="w-100 wow fadeIn" src={info2} alt="" data-wow-delay="0.2s"
                         data-wow-duration="2.0s"/>
                    <small>(*) Encuesta conducida a 1,020 inversionistas, para mayor información acceder a <a
                        target={"_blank"}
                        href={"https://www.bmo.com"}>www.bmo.com</a>.
                    </small>

                </div>
                <div className="main_blog_item">
                    <h2>IV. Estrategia de negocio</h2>
                    <br/>
                    <h6>Sin duda
                        la
                        mayoría de los IROs están conscientes de la importancia que tiene la estrategia de negocio en el
                        mensaje
                        público de las empresas, sin embargo su definición no es fácil y su explicación requiere
                        profundidad para
                        ser sólida y consistente ¿Qué elementos debe incluir la definición de la estrategia? ¿Cómo
                        evitar perderse
                        entre explicaciones ongoing de la operación y el mensaje sobre el núcleo de la estrategia?</h6>
                </div>
                <div className="s_main_text">
                    <p>
                        La explicación de la estrategia de negocio no es cuestión sencilla y la construcción de modelos
                        a su
                        alrededor ha llevado muchos años de trabajo a los estudiosos de las ciencias económicas y
                        administrativas.
                        Sin duda, uno de los modelos más recomendables en los que se puede apoyar un IRO para la
                        explicación de la
                        estrategia es el modelo de las cinco fuerzas de Porter* que sitúa la operación de un negocio en
                        medio de
                        cinco fuerzas que confluyen:
                    </p>
                    <div className="row pt-3 pb-3">
                        <div className="col-md-8">
                            <ul className="list_style">
                                <li>F1: poder de negociación de clientes;</li>
                                <li>F2: poder de negociación de proveedores;</li>
                                <li>F3: amenaza de nuevos entrantes;</li>
                                <li>F4: amenaza de productos/servicios sustitutos; y,
                                </li>
                                <li>F5: rivalidad entre competidores.</li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <img className="w-100 wow fadeInRight" src={img2} alt="" data-wow-delay="0.2s"
                                 data-wow-duration="2.0s"/>
                        </div>
                    </div>

                    <p className={"mt-10"}>La conjunción de estos factores define la ventaja competitiva de las
                        empresas, i.e.
                        aquella fortaleza que las hace únicas y que constituye la fuente de generación de valor que
                        permitirá que
                        sigan operando por los siguientes cinco, diez o treinta años. Porter identifica, de igual
                        manera, a los
                        factores que pueden determinar la permanencia de la ventaja competitiva:</p>
                    <img className="w-100 wow fadeIn" src={info3} alt="" data-wow-delay="0.2s"
                         data-wow-duration="2.0s"/>
                    <p className={"mt-10"}>Hacemos mención del modelo de Porter como un buen marco de referencia, no
                        como un
                        recetario; lo importante
                        es resaltar la necesidad del IRO por tener un modelo concreto, lógico, funcional y reconocido
                        que le permita
                        explicar la estrategia de negocios.</p>
                    <p>Es importante que la definición de estrategia sea consistente entre todos los miembros del senior
                        management, y que su explicación no sea acomodaticia a los resultados de corto plazo; pues,
                        éstos deben
                        explicarse en base a la ejecución de la estrategia y no al revés.</p>
                    <p>La consistencia del mensaje para no caer en mensajes paliativos de corto plazo constituye un reto
                        robusto,
                        y no particular al mercado mexicano; en Reino Unido, buscando la obtención de mensajes
                        estratégicos sólidos
                        en el mercado, académicos de la London School of Economics condujeron una serie de estudios
                        enfocados a
                        mostrar los beneficios de la estructuración de mensajes estratégicos funcionales a un horizonte
                        de largo
                        plazo contra mensajes estratégicos fluctuantes de corto plazo**.</p>
                    <p>La ejecución de sondeos constituye la herramienta principal del IRO para identificar el
                        entendimiento de la
                        estrategia de inversión por parte del mercado, de ahí la importancia de conducir perception
                        studies y el
                        aventurarse en una agenda de PR (NDRs, summits, etc.) o conducir programas de targeting sin una
                        previa
                        retroalimentación sobre el mensaje estratégico puede tener más efectos adversos que positivos en
                        la
                        valuación de las empresas.</p>
                    <p>La necesidad de entregar un sólido mensaje, evidencia las capacidades de análisis y síntesis que
                        debe
                        poseer el IRO para alcanzar una exitosa comunicación; muestra clara de la complejidad y la
                        importancia de su
                        función.</p>
                    <small>(*) Ver: “Five Competitive Forces That Shape Strategy”, Harvard Business Review, 1979,
                        Michael
                        Porter
                    </small>
                    <br/>
                    <small>(**) Estudios dirigidos por el Profesor de Economía de la London Business School, John Kay.
                    </small>

                </div>
            </div>
        </Single>
    )
}

export default Detail
